<template>
        <section class="mb-10">   
            <v-layout justify-space-around justify-center>
                <v-flex xs11 sm11 class="mt-10">
                        <v-card flat tile color="" width="100%" class="transparent main-content-products">
                            <v-card-title primary-title class="justify-center">
                                    <h3 v-if="!is_screen_small" class="zamu-heading transact-heading product-heading solutions-heading px-3">Powering your growth</h3><br />
                                    <h3 v-if="is_screen_small" class="zamu-heading transact-heading product-heading solutions-heading px-3">Powering <br /> your growth</h3><br />

                                    
                                    <v-card tile flat :color="`${zamuGrey}`" height="1" class="justify-center mt-5 transact-underline">
                                        <v-card-text class="transact-divider divider-purple"></v-card-text>
                                        <v-card-text class="transact-divider divider-yellow"> </v-card-text>
                                    </v-card>
                            </v-card-title>
                            <v-card-text align="center" justify="center">
                                <p class="transact-text product-text">The innovation is a one-stop shop solution to support and automate online and cloud based payment gateways for institutions of all sizes. Zamupay offers a range of services to diverse clients and market ranges centrally on one platform. </p>
                            </v-card-text>

                            <v-row>
                                    <v-col  lg="3" md="6" sm="12" xs="12" class="" v-for="(section, index) in sectionInfo" :key="index">
                             
                                           <v-card tile flat :color="`${section.color}`"  min-height="371" align="center" justify="center">
                                            <v-card-text class="section-services" align="center" justify="center">
                                                <v-img  :src="require(`../../../assets/images/${section.image}`)" :class="section.class" width="78" class="mt-5"></v-img>
                                                <h3 class="zamu-sub-heading section-header mt-6">{{ section.title }}</h3>
                                                <p class="mt-4">{{ section.text }}</p>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                            </v-row>
                        </v-card>
                </v-flex>
            </v-layout>
        </section>
</template>

<script>
import colorMixin from '@/mixins/colorMixin';
import coreMixin from '@/mixins/coreMixin';


export default {
    name: 'Services',
    mixins: [colorMixin, coreMixin],
    computed: {
        sectionInfo () {
            const data = [
                 {
                    image: 'Mobile Money Payment.png',
                    title: 'Mobile Money Payments',
                    text: 'FIRST fintech firm to integrate to mobile money payment systems in the region. This means that payments are done very fast, and without manual intervention.',
                    color: '#f4c40a',
                    class: 'mobile-money'
                },
                {
                    image: 'Merchants Collection.png',
                    title: ' Merchants Collections',
                    text: 'Facilitates payments and can assist clients to send money to a bank account, mobile money wallet or make remittances',
                    color: '#f4c40a',
                    class: 'merchant-collections'

                },
                {
                    image: 'Bulk payment.png',
                    title: 'Bulk Payments',
                    text: `Allows for the electronic sending and receiving of funds through mobile money transfers and bank transfers. `,
                    color: '#f4ba0a',
                    class: 'bulk-payments'

                },
                 {
                    image: 'Mobile Money Intergrations.png',
                    title: 'Mobile Money Integrations',
                    text: `Helps integrate your business-to-mobile money providers to both receive and make payments to and from your customers`,
                    color: '#f4ba0a',
                    class: 'mobile-integrations'

                },
                
                
              
               
            ];
            return data;

        },

    },
}
</script>

<style>
.solutions-heading {
    font-size: 40px !important;
}
.mobile-integrations {
    width: 50px !important;
}

</style>